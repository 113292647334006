import type { FetchError } from 'ofetch'

export interface DIRECTUS_FIELDS_MSG_ERRORS {
  [field: string]: {
    [code: string]: string
  }
}
// export interface DIRECTUS_REASON_MSG_ERRORS {
//   [code: string]: {
//     [reason: string]: string
//   }
// }
export interface DIRECTUS_GLOBAL_MSG_ERRORS {
  [code: string]: string
}
export interface DIRECTUS_MSG_OUTPUT {
  fields?: DIRECTUS_FIELDS_MSG_ERRORS
  // reason?: DIRECTUS_REASON_MSG_ERRORS
  global?: DIRECTUS_GLOBAL_MSG_ERRORS
}

export function catchDirectusFetchError(error: unknown) {
  const { $sentry, $openTidio } = useNuxtApp()

  if (error instanceof DOMException && error.name === 'AbortError') {
    // ignore abort error
    return
  }

  if (
    typeof error === 'object'
    && error !== null
    && 'cause' in error
    && error.cause instanceof DOMException
    && error.cause.name === 'AbortError'
  ) {
    // ignore abort error
    return
  }

  // if (
  //   typeof error === 'object'
  //   && error !== null
  //   && 'errors' in error
  //   && error.errors instanceof DOMException
  //   && error.errors.name === 'AbortError'
  // ) {
  //   // ignore server abort error
  //   return
  // }

  if (import.meta.dev) {
    console.error('[dev] catchDirectusFetchError', { error })
  }

  // redirect to auth when unauthorized
  if (isFetchError(error) && error?.response?.status === 403) {
    const router = useRouter()
    const toaster = useToaster()
    const { logout } = useDirectusAuth()

    logout()
    router.push('/auth')
    toaster.push({
      title: 'Erreur',
      message: 'Votre session a expiré, veuillez vous reconnecter',
      color: 'danger',
      icon: 'ph:warning',
      closable: true,
    }, {
      dismissible: false,
      duration: 5000,
    })
    return
  }
  // server unreachable
  else if ((isFetchError(error) && error.status === undefined) || (error instanceof Error && error?.message === 'Failed to fetch')) {
    const router = useRouter()
    const toaster = useToaster()
    const { logout } = useDirectusAuth()

    logout()
    router.push('/auth')
    toaster.push({
      title: 'Erreur',
      message: 'Impossible de se connecter au serveur, votre instance à été suspendue ou est inaccessible, veuillez contacter le support',
      color: 'danger',
      // icon: 'ph:warning',
      closable: true,
      actions: [
        {
          label: 'Support',
          icon: 'ph:question',
          props: {
            size: 'sm',
            color: 'danger',
            variant: 'pastel',
            onClick: () => {
              toaster.clear()
              $openTidio()
            },
          },
        },
      ],
    }, {
      dismissible: false,
      duration: 15000,
    })

    $sentry?.captureException(
      new Error(`[Directus Fetch Server Unreachable] ${error}`, {
        cause: error,
      }),
    )
    return
  }

  if (
    isFetchError(error) && Array.isArray(error.data?.errors)
  ) {
    // Directus error
    $sentry?.captureException(
      new Error(`[Directus Fetch Error] ${error.data.errors?.[0]?.message}`, {
        cause: error,
      }),
    )
  }
  else {
    console.error('[Directus Fetch Error] Unknown error', error)
    $sentry?.captureException(
      new Error(`[Directus Fetch Error] Unknown error`, {
        cause: error,
      }),
    )
  }
}

/**
 * Extract input errors from Directus error response
 *
 * If setFieldError is provided, it will be used to display errors within fields
 */
export function catchDirectusFormError<M extends DIRECTUS_MSG_OUTPUT>(
  messages: M,
  error: any,
  setFieldError?: (field: any, message: any) => void,
) {
  const toaster = useToaster()
  const { $sentry, $openTidio } = useNuxtApp()

  if (import.meta.dev) {
    console.error('catchDirectusFormError', { error })
  }

  if (isFetchError(error) && error.status === undefined) {
    // server unreachable
    toaster.push({
      title: 'Erreur',
      message: 'Impossible de se connecter au serveur, votre instance à été suspendue ou est inaccessible, veuillez contacter le support',
      color: 'danger',
      // icon: 'ph:warning',
      closable: true,
      actions: [
        {
          label: 'Support',
          icon: 'ph:question',
          props: {
            size: 'sm',
            color: 'danger',
            variant: 'pastel',
            onClick: () => {
              toaster.clear()
              $openTidio()
            },
          },
        },
      ],
    }, {
      dismissible: false,
      duration: 15000,
    })

    $sentry?.captureException(
      new Error(`[Directus Form Server Unreachable] ${error}`, {
        cause: error,
      }),
    )
  }
  else if (isFetchError(error) && Array.isArray(error.data?.errors)) {
    for (const e of error.data.errors) {
      const code = e?.extensions?.code as string | undefined
      const field = e?.extensions?.field as string | undefined
      // const reason = e?.extensions?.reason as string | undefined

      // Field custom error
      if (messages.fields && isFormFieldError(messages.fields, field)) {
        const message = isFormCodeFieldError(messages.fields, field, code)
          ? messages.fields[field][code]
          : code

        if (setFieldError) {
          setFieldError(field, message)
          continue
        }

        toaster.push({
          title: 'Erreur',
          message,
          color: 'danger',
          icon: 'ph:warning',
          closable: true,
        }, {
          dismissible: false,
          duration: 5000,
        })
        continue
      }

      // Global errors
      if (messages.global && isFormCodeGlobalError(messages.global, code)) {
        const message = messages.global[code]

        if (field && setFieldError) {
          setFieldError(field, message)
          continue
        }

        toaster.push({
          title: 'Erreur',
          message,
          color: 'danger',
          icon: 'ph:warning',
          closable: true,
        }, {
          dismissible: false,
          duration: 5000,
        })
        continue
      }

      if (field && typeof e?.message === 'string' && setFieldError) {
        setFieldError(field, e.message)
        return
      }

      // Unhandled errors
      if (code) {
        console.error(`[Directus Form Error] [Unhandled "${code}"] ${e?.message ?? ''}`, error)
        $sentry?.captureException(
          new Error(
            `[Directus Form Error] [Unhandled "${code}"] ${e?.message ?? ''}`,
            {
              cause: error,
            },
          ),
        )
      }
      else {
        console.error(`[Directus Form Error] [Unknown code] ${e?.message ?? ''}`, error)
        $sentry?.captureException(
          new Error(
            `[Directus Form Error] [Unknown code] ${e?.message ?? ''}`,
            {
              cause: error,
            },
          ),
        )
      }
    }
  }
  else {
    console.error(`[Directus Form Error] Unknown error`, error)
    $sentry?.captureException(
      new Error(
        `[Directus Form Error] Unknown error`,
        {
          cause: error,
        },
      ),
    )
    toaster.push({
      title: 'Erreur',
      message: `Une erreur inconnue est survenue lors de l'envois du formulaire, veuillez contacter le support`,
      color: 'danger',
      closable: true,
      actions: [
        {
          label: 'Support',
          icon: 'ph:question',
          props: {
            size: 'sm',
            color: 'danger',
            variant: 'pastel',
            onClick: () => {
              toaster.clear()
              $openTidio()
            },
          },
        },
      ],
    }, {
      dismissible: false,
      duration: 5000,
    })
  }
}

export function isFormFieldError<M extends DIRECTUS_FIELDS_MSG_ERRORS>(
  messages: M,
  field?: any,
): field is keyof M {
  return (
    messages !== undefined && typeof field === 'string' && field in messages
  )
}

export function isFormCodeFieldError<
  M extends DIRECTUS_FIELDS_MSG_ERRORS,
  T extends keyof M,
>(messages: M, field: T, code?: any): code is keyof M {
  return (
    messages !== undefined
    && typeof code === 'string'
    && code in messages[field]
  )
}

export function isFormCodeGlobalError<
  M extends DIRECTUS_GLOBAL_MSG_ERRORS,
>(messages: M, code?: any): code is keyof M {
  return messages !== undefined && typeof code === 'string' && code in messages
}

export function isFetchError<T = any>(error: unknown): error is FetchError<T> {
  return (
    error instanceof Error && 'name' in error && error.name === 'FetchError'
  )
}
